// PTINewsPage.js
import React, { useEffect, useState } from "react";
import {
  DatePicker,
  List,
  Card,
  Spin,
  Alert,
  Typography,
  Button,
  Modal,
  Row,
  Col,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import Layout from "../Layout";
import moment from "moment";

const { Title, Paragraph, Text } = Typography;

const PTINewsPage = () => {
  const accessToken = localStorage.getItem("accessToken");
  const server_url = "https://newsbot.newrlabs.com"; // Base server URL

  // State for news groups, selected date, loading, error
  const [newsGroups, setNewsGroups] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for modal (detailed view)
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  // Fetch news from the backend; optionally filter by date
  const fetchNews = async (date = null) => {
    setLoading(true);
    setError(null);
    try {
      let url = `${server_url}/list-pti-data`;
      if (date) {
        const formattedDate = date.format("YYYY-MM-DD");
        url += `?date=${formattedDate}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.detail || "Failed to fetch PTI news.");
      }
      const data = await response.json();
      // Expecting data in the format: { data: [ { date: 'YYYY-MM-DD', data: [ ... ] }, ... ] }
      setNewsGroups(data.data || []);
    } catch (err) {
      console.error("Error fetching PTI news:", err);
      setError(err.message || "Failed to fetch PTI news.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // When a date is selected, update filter and fetch new data
  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchNews(date);
  };

  const handleRefresh = () => {
    fetchNews(selectedDate);
  };

  // Open modal to show full details
  const handleReadMore = (post) => {
    setSelectedPost(post);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedPost(null);
  };

  return (
    <Layout pageTitle="PTI News">
      <div style={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
        {/* Header with Gradient Background */}
        <div
          style={{
            background: "linear-gradient(135deg, #667eea, #764ba2)",
            padding: "40px",
            borderRadius: "8px",
            marginBottom: "30px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          <Title style={{ color: "#fff", marginBottom: "10px" }}>
            PTI News Dashboard
          </Title>
          <Paragraph style={{ color: "#e0e0e0", fontSize: "16px" }}>
            View all news received automatically from the PTI API. Use the filter
            below to narrow your search.
          </Paragraph>
          <Row justify="center" gutter={16} style={{ marginTop: "20px" }}>
            <Col>
              <DatePicker
                onChange={handleDateChange}
                placeholder="Select Date"
                style={{ borderRadius: "4px" }}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={handleRefresh}
                style={{ borderRadius: "4px" }}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </div>

        {/* Error Alert */}
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            closable
            style={{ marginBottom: "20px" }}
          />
        )}

        {/* News List */}
        {loading ? (
          <Spin
            tip="Loading PTI news..."
            size="large"
            style={{ display: "block", margin: "0 auto" }}
          />
        ) : (
          <>
            {newsGroups.length === 0 ? (
              <Alert message="No news found." type="info" showIcon />
            ) : (
              newsGroups.map((group, groupIndex) => (
                <div key={groupIndex} style={{ marginBottom: "40px" }}>
                  <Title level={2}>{group.date}</Title>
                  <List
                    grid={{
                      gutter: 24,
                      xs: 1,
                      sm: 2,
                      md: 2,
                      lg: 3,
                      xl: 3,
                      xxl: 3,
                    }}
                    dataSource={group.data}
                    locale={{ emptyText: "No news for this date." }}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <Card
                          hoverable
                          style={{
                            borderRadius: "8px",
                            overflow: "hidden",
                            minHeight: "240px",
                          }}
                          title={
                            <Text strong style={{ fontSize: "16px" }}>
                              {item.Headline || "Untitled News"}
                            </Text>
                          }
                          extra={
                            <Text type="secondary" style={{ fontSize: "12px" }}>
                              {item.FileName || ""}
                            </Text>
                          }
                          actions={[
                            <Button
                              type="link"
                              onClick={() => handleReadMore(item)}
                              key="readmore"
                            >
                              Read More
                            </Button>,
                          ]}
                        >
                          {/* Truncated Story Preview */}
                          <div
                            style={{
                              height: "100px",
                              overflow: "hidden",
                              marginBottom: "10px",
                            }}
                            dangerouslySetInnerHTML={{ __html: item.story }}
                          />
                        </Card>
                      </List.Item>
                    )}
                  />
                </div>
              ))
            )}
          </>
        )}

        {/* Modal for Full News Details */}
        <Modal
          visible={modalVisible}
          title={selectedPost?.Headline || "News Detail"}
          onCancel={handleModalClose}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
          ]}
          width={800}
        >
          {selectedPost && (
            <div>
              <Text strong>{selectedPost.FileName}</Text>
              <div
                style={{ marginTop: "20px" }}
                dangerouslySetInnerHTML={{ __html: selectedPost.story }}
              />
            </div>
          )}
        </Modal>
      </div>
    </Layout>
  );
};

export default PTINewsPage;
