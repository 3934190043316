import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Layout} from "antd";
import {FileTextOutlined, HomeOutlined, LogoutOutlined, ProfileOutlined, SettingOutlined,} from "@ant-design/icons";

const {Sider} = Layout;

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        navigate("/"); // Redirect to home or login page after logout
    };
    const getSelected = (path) => (location.pathname === path ? "bg-[#4db6ff]" : "");
    const getIconColor = (path) => (location.pathname === path ? "#ffffff" : "#3c7fbf");


    // Define menu items
    const menuItems = [
        {text: "Home", icon: <HomeOutlined/>, path: "/HomePage"},
        {text: "PTI News", icon: <FileTextOutlined/>, path: "/PTINewsPage"},
        {text: "News Posted", icon: <FileTextOutlined/>, path: "/ProcessedNewsPage"},
        {text: "Draft News", icon: <FileTextOutlined/>, path: "/Draft"},

        {text: "Settings", icon: <SettingOutlined/>, path: "/settings"},
        // {text: "Profile", icon: <ProfileOutlined/>, path: "/TeacherProfilePage"},
        {text: "Logout", icon: <LogoutOutlined/>, path: "logout"},
    ];

    const handleMenuClick = (path, text) => {
        if (text === "Logout") {
            localStorage.removeItem("accessToken");
            navigate("/"); // Redirect to home or login page after logout
        } else if (text === "collapse") {
            setCollapsed(!collapsed);
        } else {
            navigate(path);
        }
    };

    return (
        <div
            className="pt-6 h-screen w-full"
            style={{
                backgroundColor: "#000000", // Black background
                padding: "12px 8px",
                fontFamily: "Gotham, sans-serif",
            }}
        >
            {/* Logo Section */}
            <div
                className="logo"
                style={{
                    color: "#d55f5f", // Yellow color for logo
                    textAlign: "center",
                    padding: "16px",
                    fontSize: "22px", // Slightly larger logo font size
                    fontWeight: "bold",
                    fontFamily: "League Spartan, sans-serif", // Typography for heading
                    textTransform: "uppercase",
                }}
            >
                Swadesi Journalist
            </div>

            {/* Menu Items */}
            <div
                className="menu"
                style={{
                    marginTop: "24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px", // Spacing between menu items
                }}
            >
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleMenuClick(item.path, item.text)}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "12px 16px",
                            borderRadius: "12px",
                            backgroundColor: location.pathname === item.path ? "#d55f5f" : "transparent", // Highlight active menu
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                        }}
                    >
            <span
                style={{
                    color: location.pathname === item.path ? "#000000" : "#d55f5f", // Icon color
                    fontSize: "20px",
                    marginRight: "12px",
                }}
            >
              {item.icon}
            </span>
                        <span
                            style={{
                                color: location.pathname === item.path ? "#000000" : "#FFFFFF", // Text color
                                fontSize: "16px",
                                fontWeight: "500",
                            }}
                        >
              {item.text}
            </span>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default Sidebar;
