import React from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";

const Layout = ({ children, pageTitle }) => {
  return (
    <div>
      <header className="w-full bg-white fixed">
        <Header pageTitle={pageTitle} />
      </header>
      <div className="flex">
        <aside className="w-[16%] h-screen fixed top-0 z-50">
          <Sidebar />
        </aside>
        <div className="ml-[16%] mt-5 w-full ">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
