import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
      console.log("Navigating to login page due to no token.");
      return <Navigate to="/" />;
  }

  return children;
};


export default ProtectedRoute;