// import { useState, useEffect } from 'react';

// /**
//  * A custom hook for managing the conversation between the user and the AI.
//  *
//  * @returns {Object} An object containing the `messages` array, the `addMessage` function, the `clearMessages` function, and the `loadMessage` function.
//  */
// const useMessageCollection = () => {
//   const [messages, setMessages] = useState([]);
//   const [bot_id, setBotId] = useState(null);
//   const [chat_id, setChatId] = useState(null);
//   useEffect(() => {
//     const storedMessages = JSON.parse(localStorage.getItem('messages'));
//     const storedBotId = localStorage.getItem('bot_id');
//     const storedChatId = localStorage.getItem('chat_id');

//     if (storedMessages) {
//       setMessages(storedMessages);
//     }
//     if (storedBotId) {
//       setBotId(storedBotId);
//     }
//     if (storedChatId) {
//       setChatId(storedChatId);
//     }
//   }, []);
//   useEffect(() => {
//     if (messages.length) {
//       localStorage.setItem('messages', JSON.stringify(messages));
//     }
//     localStorage.setItem('bot_id', bot_id);
//     localStorage.setItem('chat_id', chat_id);
//   }, [messages, bot_id, chat_id]);


//   /**
//    * A function for adding a new message to the collection.
//    *
//    * @param {Object} message - The message to add to the collection.
//    */
//   const addMessage = (message) => {
//     // Special handling for system messages containing botId and chatId
//     if (message.systemMessage) {
//       setBotId(message.bot_id);
//       setChatId(message.chat_id);
//     } else {
//       setMessages((prev) => [...prev, message]);
//     }
//   };

//   const clearChat = () => {
//     localStorage.setItem('messages', JSON.stringify([]));
//     setMessages([]);
//   };

//   const addBotDetails = (botDetails) => {
//     setBotId(botDetails.bot_id);
//     setChatId(botDetails.chat_id);
//   };

//   return { messages, addMessage, clearChat, addBotDetails, bot_id, chat_id };
// };

// export default useMessageCollection;

import {useState, useEffect} from 'react';

const useMessageCollection = () => {
    const [messages, setMessages] = useState([]);
    const [bot_id, setBotId] = useState(null);
    const [chat_id, setChatId] = useState(null);

    useEffect(() => {
        try {
            const storedMessages = JSON.parse(localStorage.getItem('messages') || '[]');
            const storedBotId = localStorage.getItem('bot_id');
            const storedChatId = localStorage.getItem('chat_id');

            if (storedMessages.length) {
                setMessages(storedMessages);
            }
            if (storedBotId) {
                setBotId(storedBotId);
            }
            if (storedChatId) {
                setChatId(storedChatId);
            }
        } catch (error) {
            console.error('Error reading from localStorage:', error);
            // Optionally clear or handle corrupt local storage data
        }
    }, []);

    useEffect(() => {
        // Save data to localStorage when messages, bot_id, or chat_id change
        localStorage.setItem('messages', JSON.stringify(messages));
        localStorage.setItem('bot_id', bot_id || '');  // Store empty string if null
        localStorage.setItem('chat_id', chat_id || '');  // Store empty string if null
    }, [messages, bot_id, chat_id]);

    const addMessage = (message) => {
        if (message.systemMessage) {
            setBotId(message.bot_id);
            setChatId(message.chat_id);
        } else {
            setMessages((prev) => [...prev, message]);
        }
    };

    const clearChat = () => {
        // Clear localStorage and state for messages, bot_id, and chat_id
        localStorage.removeItem('messages');
        localStorage.removeItem('bot_id');
        localStorage.removeItem('chat_id');
        setMessages([]);
        setBotId(null);  // Clear the bot_id
        setChatId(null);  // Clear the chat_id
    };

    const addBotDetails = (botDetails) => {
        setBotId(botDetails.bot_id);
        setChatId(botDetails.chat_id);
    };

    return {messages, addMessage, clearChat, addBotDetails, bot_id, chat_id};
};

export default useMessageCollection;

