// SettingsPage.js
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Tabs,
  Typography,
} from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  SaveOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import Layout from "../Layout"; // Your custom layout component

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const SettingsPage = () => {
  // Retrieve the access token and define the server URL.
  const accessToken = localStorage.getItem("accessToken");
  const server_url = "https://newsbot.newrlabs.com";

  /* -------------------------
     PTI Credentials States
  -------------------------- */
  const [ptiCredentials, setPtiCredentials] = useState(null);
  const [ptiFetching, setPtiFetching] = useState(false);
  const [ptiModalVisible, setPtiModalVisible] = useState(false);
  const [ptiLoading, setPtiLoading] = useState(false);
  const [ptiForm] = Form.useForm();

  /* -----------------------------
     WordPress Credentials States
  ------------------------------ */
  const [wpCredentials, setWPCredentials] = useState(null);
  const [wpFetching, setWPFetching] = useState(false);
  const [wpModalVisible, setWPModalVisible] = useState(false);
  const [wpLoading, setWPLoading] = useState(false);
  const [wpForm] = Form.useForm();

  /* --------------------------
     Global Styles States
  --------------------------- */
  const [globalStyles, setGlobalStyles] = useState(null);
  const [stylesFetching, setStylesFetching] = useState(false);
  const [stylesModalVisible, setStylesModalVisible] = useState(false);
  const [stylesLoading, setStylesLoading] = useState(false);
  const [stylesForm] = Form.useForm();

  /* --------------------------
     Fetch Data on Component Mount
  --------------------------- */
  useEffect(() => {
    fetchPTICredentials();
    fetchWPCredentials();
    fetchGlobalStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  /* --------------------------
     API Calls for PTI Credentials
  --------------------------- */
  const fetchPTICredentials = async () => {
    try {
      setPtiFetching(true);
      const response = await fetch(`${server_url}/get-pti-credentials`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.centercode) {
          setPtiCredentials(data);
          ptiForm.setFieldsValue(data);
        } else {
          setPtiCredentials(null);
        }
      } else {
        setPtiCredentials(null);
        const errorData = await response.json();
        message.error(errorData.detail || "Failed to fetch PTI credentials.");
      }
    } catch (error) {
      console.error("Error fetching PTI credentials:", error);
      message.error("Error fetching PTI credentials.");
    } finally {
      setPtiFetching(false);
    }
  };

  const handlePTISubmit = async (values) => {
    try {
      setPtiLoading(true);
      const response = await fetch(`${server_url}/set-pti-credentials`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        const data = await response.json();
        message.success(data.message || "PTI credentials updated successfully.");
        setPtiCredentials(values);
        setPtiModalVisible(false);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update PTI credentials.");
      }
    } catch (error) {
      console.error("Error updating PTI credentials:", error);
      message.error(error.message || "Error updating PTI credentials.");
    } finally {
      setPtiLoading(false);
    }
  };

  /* --------------------------
     API Calls for WordPress Credentials
  --------------------------- */
  const fetchWPCredentials = async () => {
    try {
      setWPFetching(true);
      const response = await fetch(`${server_url}/get-wp-credentials`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.wp_url) {
          setWPCredentials(data);
          wpForm.setFieldsValue(data);
        } else {
          setWPCredentials(null);
        }
      } else {
        setWPCredentials(null);
        const errorData = await response.json();
        message.error(errorData.detail || "Failed to fetch WordPress credentials.");
      }
    } catch (error) {
      console.error("Error fetching WordPress credentials:", error);
      message.error("Error fetching WordPress credentials.");
    } finally {
      setWPFetching(false);
    }
  };

  const handleWPSubmit = async (values) => {
    try {
      setWPLoading(true);
      const response = await fetch(`${server_url}/set-wp-credentials`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        const data = await response.json();
        message.success(data.message || "WordPress credentials updated successfully.");
        setWPCredentials(values);
        setWPModalVisible(false);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update WordPress credentials.");
      }
    } catch (error) {
      console.error("Error updating WordPress credentials:", error);
      message.error(error.message || "Error updating WordPress credentials.");
    } finally {
      setWPLoading(false);
    }
  };

  /* --------------------------
     API Calls for Global Styles
  --------------------------- */
  const fetchGlobalStyles = async () => {
    try {
      setStylesFetching(true);
      const response = await fetch(`${server_url}/get-styles`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.font_size) {
          setGlobalStyles(data);
          stylesForm.setFieldsValue(data);
        } else {
          setGlobalStyles(null);
        }
      } else {
        setGlobalStyles(null);
        const errorData = await response.json();
        message.error(errorData.detail || "Failed to fetch global styles.");
      }
    } catch (error) {
      console.error("Error fetching global styles:", error);
      message.error("Error fetching global styles.");
    } finally {
      setStylesFetching(false);
    }
  };

  const handleStylesSubmit = async (values) => {
    try {
      setStylesLoading(true);
      const response = await fetch(`${server_url}/update-styles`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        const data = await response.json();
        message.success(data.message || "Global styles updated successfully.");
        setGlobalStyles(values);
        setStylesModalVisible(false);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update global styles.");
      }
    } catch (error) {
      console.error("Error updating global styles:", error);
      message.error(error.message || "Error updating global styles.");
    } finally {
      setStylesLoading(false);
    }
  };

  return (
    <Layout pageTitle="Settings">
      <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        {/* Header Section */}
        <div
          style={{
            background: "linear-gradient(135deg, #667eea, #764ba2)",
            padding: "30px",
            borderRadius: "8px",
            marginBottom: "30px",
            textAlign: "center",
            color: "#fff",
          }}
        >
          <Title style={{ color: "#fff", margin: 0 }}>Application Settings</Title>
          <Paragraph style={{ color: "#e0e0e0", marginTop: "10px" }}>
            Configure your PTI credentials, WordPress credentials, and global styles below.
          </Paragraph>
        </div>

        {/* Tabs Section */}
        <Tabs defaultActiveKey="pti" size="large">
          {/* PTI Credentials Tab */}
          <TabPane tab="PTI Credentials" key="pti">
            <Spin
              spinning={ptiFetching}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
              <Card
                bordered={false}
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  marginBottom: "20px",
                }}
                title={
                  <Title level={3} style={{ margin: 0 }}>
                    PTI Credentials
                  </Title>
                }
                extra={
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => setPtiModalVisible(true)}
                  >
                    {ptiCredentials ? "Edit Credentials" : "Set Credentials"}
                  </Button>
                }
              >
                {ptiCredentials ? (
                  <div>
                    <Text strong>Center Code:</Text>
                    <p>{ptiCredentials.centercode}</p>
                  </div>
                ) : (
                  <Alert
                    message="PTI credentials are not set."
                    description="Please set your PTI credentials."
                    type="warning"
                    showIcon
                  />
                )}
              </Card>
            </Spin>
            <Modal
              title={ptiCredentials ? "Edit PTI Credentials" : "Set PTI Credentials"}
              visible={ptiModalVisible}
              onCancel={() => setPtiModalVisible(false)}
              footer={null}
              destroyOnClose
            >
              <Form
                form={ptiForm}
                layout="vertical"
                initialValues={{
                  centercode: ptiCredentials ? ptiCredentials.centercode : "",
                }}
                onFinish={handlePTISubmit}
              >
                <Form.Item
                  label={<Text strong>Center Code</Text>}
                  name="centercode"
                  rules={[{ required: true, message: "Please enter the center code." }]}
                >
                  <Input placeholder="Enter PTI Center Code" size="large" disabled={ptiLoading} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={ptiLoading}
                    icon={<SaveOutlined />}
                    block
                  >
                    {ptiCredentials ? "Update Credentials" : "Set Credentials"}
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </TabPane>

          {/* WordPress Credentials Tab */}
          <TabPane tab="WordPress Credentials" key="wp">
            <Spin
              spinning={wpFetching}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
              <Card
                bordered={false}
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  marginBottom: "20px",
                }}
                title={
                  <Title level={3} style={{ margin: 0 }}>
                    WordPress Credentials
                  </Title>
                }
                extra={
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => setWPModalVisible(true)}
                  >
                    {wpCredentials ? "Edit Credentials" : "Set Credentials"}
                  </Button>
                }
              >
                {wpCredentials ? (
                  <>
                    <div>
                      <Text strong>WordPress URL:</Text>
                      <p>{wpCredentials.wp_url}</p>
                    </div>
                    <Divider />
                    <div>
                      <Text strong>Media URL:</Text>
                      <p>{wpCredentials.media_url}</p>
                    </div>
                    <Divider />
                    <div>
                      <Text strong>Username:</Text>
                      <p>{wpCredentials.username}</p>
                    </div>
                    <Divider />
                    <div>
                      <Text strong>Password:</Text>
                      <p>••••••••</p>
                    </div>
                  </>
                ) : (
                  <Alert
                    message="WordPress credentials are not set."
                    description="Please set your WordPress credentials."
                    type="warning"
                    showIcon
                  />
                )}
              </Card>
            </Spin>
            <Modal
              title={wpCredentials ? "Edit WordPress Credentials" : "Set WordPress Credentials"}
              visible={wpModalVisible}
              onCancel={() => setWPModalVisible(false)}
              footer={null}
              destroyOnClose
            >
              <Form
                form={wpForm}
                layout="vertical"
                initialValues={{
                  wp_url: wpCredentials ? wpCredentials.wp_url : "",
                  media_url: wpCredentials ? wpCredentials.media_url : "",
                  username: wpCredentials ? wpCredentials.username : "",
                  password: wpCredentials ? wpCredentials.password : "",
                }}
                onFinish={handleWPSubmit}
              >
                <Form.Item
                  label={<Text strong>WordPress URL</Text>}
                  name="wp_url"
                  rules={[
                    { required: true, message: "Please enter the WordPress URL." },
                    { type: "url", message: "Please enter a valid URL." },
                  ]}
                >
                  <Input
                    placeholder="https://yourwordpresssite.com/"
                    size="large"
                    disabled={wpLoading}
                  />
                </Form.Item>
                <Form.Item
                  label={<Text strong>Media URL</Text>}
                  name="media_url"
                  rules={[
                    { required: true, message: "Please enter the Media URL." },
                    { type: "url", message: "Please enter a valid URL." },
                  ]}
                >
                  <Input
                    placeholder="https://yourwordpresssite.com/"
                    size="large"
                    disabled={wpLoading}
                  />
                </Form.Item>
                <Form.Item
                  label={<Text strong>Username</Text>}
                  name="username"
                  rules={[{ required: true, message: "Please enter the username." }]}
                >
                  <Input placeholder="Enter username" size="large" disabled={wpLoading} />
                </Form.Item>
                <Form.Item
                  label={<Text strong>Password</Text>}
                  name="password"
                  rules={[{ required: true, message: "Please enter the password." }]}
                >
                  <Input.Password
                    placeholder="Enter password"
                    size="large"
                    disabled={wpLoading}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={wpLoading}
                    icon={<SaveOutlined />}
                    block
                  >
                    {wpCredentials ? "Update Credentials" : "Set Credentials"}
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </TabPane>

          {/* Global Styles Tab */}
          <TabPane tab="Global Styles" key="styles">
            <Spin
              spinning={stylesFetching}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
              <Card
                bordered={false}
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  marginBottom: "20px",
                }}
                title={
                  <Title level={3} style={{ margin: 0 }}>
                    Global Styles
                  </Title>
                }
                extra={
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => setStylesModalVisible(true)}
                  >
                    {globalStyles ? "Edit Styles" : "Set Styles"}
                  </Button>
                }
              >
                {globalStyles ? (
                  <>
                    <div>
                      <Text strong>Font Size:</Text>
                      <p>{globalStyles.font_size}</p>
                    </div>
                    <Divider />
                    <div>
                      <Text strong>Font Family:</Text>
                      <p>{globalStyles.font_family}</p>
                    </div>
                    <Divider />
                    <div>
                      <Text strong>Line Height:</Text>
                      <p>{globalStyles.line_height}</p>
                    </div>
                    <Divider />
                    <div>
                      <Text strong>Text Color:</Text>
                      <p>{globalStyles.text_color}</p>
                    </div>
                    <Divider />
                    <div>
                      <Text strong>Background Color:</Text>
                      <p>{globalStyles.background_color}</p>
                    </div>
                    <Divider />
                    <div>
                      <Text strong>Header Font Size:</Text>
                      <p>{globalStyles.header_font_size}</p>
                    </div>
                    <Divider />
                    <div>
                      <Text strong>Header Font Family:</Text>
                      <p>{globalStyles.header_font_family}</p>
                    </div>
                  </>
                ) : (
                  <Alert
                    message="Global styles are not set."
                    description="Please set your global styles."
                    type="warning"
                    showIcon
                  />
                )}
              </Card>
            </Spin>
            <Modal
              title={globalStyles ? "Edit Global Styles" : "Set Global Styles"}
              visible={stylesModalVisible}
              onCancel={() => setStylesModalVisible(false)}
              footer={null}
              destroyOnClose
            >
              <Form
                form={stylesForm}
                layout="vertical"
                initialValues={{
                  font_size: globalStyles ? globalStyles.font_size : "",
                  font_family: globalStyles ? globalStyles.font_family : "",
                  line_height: globalStyles ? globalStyles.line_height : "",
                  text_color: globalStyles ? globalStyles.text_color : "",
                  background_color: globalStyles ? globalStyles.background_color : "",
                  header_font_size: globalStyles ? globalStyles.header_font_size : "",
                  header_font_family: globalStyles ? globalStyles.header_font_family : "",
                }}
                onFinish={handleStylesSubmit}
              >
                <Form.Item
                  label={<Text strong>Font Size</Text>}
                  name="font_size"
                  rules={[{ required: true, message: "Please enter the font size." }]}
                >
                  <Input placeholder="e.g. 16px" size="large" disabled={stylesLoading} />
                </Form.Item>
                <Form.Item
                  label={<Text strong>Font Family</Text>}
                  name="font_family"
                  rules={[{ required: true, message: "Please enter the font family." }]}
                >
                  <Input placeholder="e.g. Georgia, serif" size="large" disabled={stylesLoading} />
                </Form.Item>
                <Form.Item
                  label={<Text strong>Line Height</Text>}
                  name="line_height"
                  rules={[{ required: true, message: "Please enter the line height." }]}
                >
                  <Input placeholder="e.g. 1.6" size="large" disabled={stylesLoading} />
                </Form.Item>
                <Form.Item
                  label={<Text strong>Text Color</Text>}
                  name="text_color"
                  rules={[{ required: true, message: "Please enter the text color." }]}
                >
                  <Input placeholder="e.g. #555" size="large" disabled={stylesLoading} />
                </Form.Item>
                <Form.Item
                  label={<Text strong>Background Color</Text>}
                  name="background_color"
                  rules={[{ required: true, message: "Please enter the background color." }]}
                >
                  <Input placeholder="e.g. #ffffff" size="large" disabled={stylesLoading} />
                </Form.Item>
                <Form.Item
                  label={<Text strong>Header Font Size</Text>}
                  name="header_font_size"
                  rules={[{ required: true, message: "Please enter the header font size." }]}
                >
                  <Input placeholder="e.g. 24px" size="large" disabled={stylesLoading} />
                </Form.Item>
                <Form.Item
                  label={<Text strong>Header Font Family</Text>}
                  name="header_font_family"
                  rules={[{ required: true, message: "Please enter the header font family." }]}
                >
                  <Input placeholder="e.g. Georgia, serif" size="large" disabled={stylesLoading} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={stylesLoading}
                    icon={<SaveOutlined />}
                    block
                  >
                    {globalStyles ? "Update Styles" : "Set Styles"}
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default SettingsPage;
