import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./Components/Login";
import ProtectedRoute from "./Components/ProtectedRoute";
import {ChatContextProvider} from "./context/chatContext";
import HomePage from "./Pages/HomePage";
import ProcessedNewsPage from "./Pages/ProcessedNewsPage";
import PTINewsPage from "./Pages/PTINewsPage";
 import Signup from "./Components/Signup";
 import Settings from "./Pages/Settings";
import DraftNewsPage from "./Pages/DraftNewsPage";

function App() {
    return (
        <ChatContextProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/Signup" element={<Signup/>}/>

                    <Route path="/HomePage" element={
                        <ProtectedRoute>
                            <HomePage/>
                        </ProtectedRoute>

                    }/>


                    <Route path="/settings" element={
                        <ProtectedRoute>
                            <Settings/>
                        </ProtectedRoute>

                    }/>

                    <Route path="/ProcessedNewsPage" element={
                        <ProtectedRoute>
                            <ProcessedNewsPage/>
                        </ProtectedRoute>

                    }/>

                    <Route path="/PTINewsPage" element={
                        <ProtectedRoute>
                            <PTINewsPage/>
                        </ProtectedRoute>

                    }/>


                    <Route path="/Draft" element={
                        <ProtectedRoute>
                            <DraftNewsPage/>
                        </ProtectedRoute>

                    }/>

                </Routes>
            </Router>
        </ChatContextProvider>
    );
}

export default App;
