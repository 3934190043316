// HomePage.js
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  InputNumber,
  message,
  Spin,
  Typography,
  Row,
  Col,
} from "antd";
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
  StopOutlined,
} from "@ant-design/icons";
import Layout from "../Layout";

const { Title, Paragraph, Text } = Typography;

const HomePage = () => {
  const accessToken = localStorage.getItem("accessToken");
  const server_url = "https://newsbot.newrlabs.com"; // Replace with your actual server URL

  // State for automation stats (from /automation-stats)
  const [automationStats, setAutomationStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for dashboard daily summary (from /homepage)
  const [dashboardStats, setDashboardStats] = useState(null);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [dashboardError, setDashboardError] = useState(null);

  // Loading states for action buttons.
  const [startLoading, setStartLoading] = useState(false);
  const [pauseLoading, setPauseLoading] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);

  const [form] = Form.useForm();

  // Fetch automation stats from /automation-stats endpoint.
  const fetchAutomationStats = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${server_url}/automation-stats`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.detail || "Failed to fetch automation stats.");
      }
      const data = await response.json();
      setAutomationStats(data);
    } catch (err) {
      console.error("Error fetching automation stats:", err);
      setError("Failed to fetch automation stats.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch daily summary stats from /homepage endpoint.
  const fetchDashboardStats = async () => {
    try {
      setDashboardLoading(true);
      const response = await fetch(`${server_url}/homepage`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.detail || "Failed to fetch dashboard stats.");
      }
      const data = await response.json();
      setDashboardStats(data);
    } catch (err) {
      console.error("Error fetching dashboard stats:", err);
      setDashboardError("Failed to fetch dashboard stats.");
    } finally {
      setDashboardLoading(false);
    }
  };

  useEffect(() => {
    fetchAutomationStats();
    fetchDashboardStats();
  }, [accessToken]);

  // Action handlers (start, pause, resume, stop)
  const handleStartAutomation = async (values) => {
    try {
      setStartLoading(true);
      const response = await fetch(`${server_url}/start-automation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to start automation.");
      }
      const data = await response.json();
      message.success(data.message);
      fetchAutomationStats();
      fetchDashboardStats();
    } catch (err) {
      console.error("Error starting automation:", err);
      message.error(err.message || "Failed to start automation.");
    } finally {
      setStartLoading(false);
    }
  };

  const handlePauseAutomation = async () => {
    try {
      setPauseLoading(true);
      const response = await fetch(`${server_url}/pause-automation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to pause automation.");
      }
      const data = await response.json();
      message.success(data.message);
      fetchAutomationStats();
      fetchDashboardStats();
    } catch (err) {
      console.error("Error pausing automation:", err);
      message.error(err.message || "Failed to pause automation.");
    } finally {
      setPauseLoading(false);
    }
  };

  const handleResumeAutomation = async () => {
    try {
      setResumeLoading(true);
      const response = await fetch(`${server_url}/resume-automation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to resume automation.");
      }
      const data = await response.json();
      message.success(data.message);
      fetchAutomationStats();
      fetchDashboardStats();
    } catch (err) {
      console.error("Error resuming automation:", err);
      message.error(err.message || "Failed to resume automation.");
    } finally {
      setResumeLoading(false);
    }
  };

  const handleStopAutomation = async () => {
    try {
      setStopLoading(true);
      const response = await fetch(`${server_url}/stop-automation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to stop automation.");
      }
      const data = await response.json();
      message.success(data.message);
      fetchAutomationStats();
      fetchDashboardStats();
    } catch (err) {
      console.error("Error stopping automation:", err);
      message.error(err.message || "Failed to stop automation.");
    } finally {
      setStopLoading(false);
    }
  };

  return (
    <Layout pageTitle="Automation Dashboard">
      <div style={{ padding: "20px", maxWidth: "900px", margin: "0 auto" }}>
        {/* Header Section */}
        <div
          style={{
            background: "linear-gradient(135deg, #4b79a1, #283e51)",
            borderRadius: "8px",
            padding: "40px",
            marginBottom: "30px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          <Title style={{ color: "#fff", marginBottom: "10px" }}>
            Automation Dashboard
          </Title>
          <Paragraph style={{ color: "#e0e0e0", fontSize: "16px" }}>
            Monitor and control your news automation job.
          </Paragraph>
        </div>

        {/* Display errors */}
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            closable
            style={{ marginBottom: 20 }}
          />
        )}
        {dashboardError && (
          <Alert
            message="Error"
            description={dashboardError}
            type="error"
            showIcon
            closable
            style={{ marginBottom: 20 }}
          />
        )}

        {loading ? (
          <Spin size="large" style={{ display: "block", margin: "0 auto" }} />
        ) : (
          automationStats && (
            <>
              {/* Automation Statistics Section */}
              <Card
                bordered={false}
                style={{
                  marginBottom: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Text strong>Status:</Text>
                    <div style={{ fontSize: "18px", marginTop: "4px" }}>
                      {automationStats.status}
                    </div>
                  </Col>
                  <Col span={12}>
                    <Text strong>Interval (hours):</Text>
                    <div style={{ fontSize: "18px", marginTop: "4px" }}>
                      {automationStats.interval_hours || "N/A"}
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                  <Col span={8}>
                    <Text strong>Total PTI Items:</Text>
                    <div style={{ fontSize: "18px", marginTop: "4px" }}>
                      {automationStats.total_pti_items}
                    </div>
                  </Col>
                  <Col span={8}>
                    <Text strong>Total WP Posts:</Text>
                    <div style={{ fontSize: "18px", marginTop: "4px" }}>
                      {automationStats.total_wp_posts}
                    </div>
                  </Col>
                  <Col span={8}>
                    <Text strong>Total Runs:</Text>
                    <div style={{ fontSize: "18px", marginTop: "4px" }}>
                      {automationStats.total_runs}
                    </div>
                  </Col>
                </Row>
              </Card>

              {/* Dashboard Daily Summary Section from /homepage */}
              {dashboardLoading ? (
                <Spin size="large" style={{ display: "block", margin: "0 auto" }} />
              ) : (
                dashboardStats && (
                  <Card
                    title={`Dashboard Summary for ${dashboardStats.date}`}
                    bordered={false}
                    style={{
                      marginBottom: "20px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Row gutter={16}>
                      <Col span={8}>
                        <Text strong>Total PTI Items Today:</Text>
                        <div style={{ fontSize: "18px", marginTop: "4px" }}>
                          {dashboardStats.total_pti_items}
                        </div>
                      </Col>
                      <Col span={8}>
                        <Text strong>Drafts Created Today:</Text>
                        <div style={{ fontSize: "18px", marginTop: "4px" }}>
                          {dashboardStats.drafts_created_today}
                        </div>
                      </Col>
                      <Col span={8}>
                        <Text strong>Published Today:</Text>
                        <div style={{ fontSize: "18px", marginTop: "4px" }}>
                          {dashboardStats.published_today}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )
              )}

              {/* Recent Runs Section */}
              <Card
                title="Recent Runs"
                bordered={false}
                style={{
                  marginBottom: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                }}
              >
                {automationStats.recent_runs && automationStats.recent_runs.length > 0 ? (
                  <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {automationStats.recent_runs.map((run, index) => (
                      <div key={index} style={{ marginBottom: "12px" }}>
                        <Text strong>{run.time}</Text>
                        <div style={{ fontSize: "14px", marginTop: "2px" }}>
                          Items Processed: {run.items_processed} | Posts Uploaded: {run.posts_uploaded}
                        </div>
                        {index < automationStats.recent_runs.length - 1 && (
                          <Divider style={{ margin: "8px 0" }} />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <Text>No recent runs available.</Text>
                )}
              </Card>

              {/* Automation Controls Section */}
              <Card
                bordered={false}
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                }}
              >
                {automationStats.status === "running" ? (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      icon={<PauseCircleOutlined />}
                      onClick={handlePauseAutomation}
                      loading={pauseLoading}
                      style={{ marginRight: "10px" }}
                    >
                      Pause
                    </Button>
                    <Button
                      danger
                      icon={<StopOutlined />}
                      onClick={handleStopAutomation}
                      loading={stopLoading}
                    >
                      Stop
                    </Button>
                  </div>
                ) : automationStats.status === "paused" ? (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      icon={<ReloadOutlined />}
                      onClick={handleResumeAutomation}
                      loading={resumeLoading}
                      style={{ marginRight: "10px" }}
                    >
                      Resume
                    </Button>
                    <Button
                      danger
                      icon={<StopOutlined />}
                      onClick={handleStopAutomation}
                      loading={stopLoading}
                    >
                      Stop
                    </Button>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Alert
                      message="No automation job configured."
                      type="warning"
                      showIcon
                      style={{ marginBottom: "20px" }}
                    />
                    <Form
                      form={form}
                      layout="inline"
                      onFinish={handleStartAutomation}
                      initialValues={{ interval_hours: 2.0 }}
                      style={{ justifyContent: "center" }}
                    >
                      <Form.Item
                        name="interval_hours"
                        label="Interval (hours)"
                        rules={[
                          { required: true, message: "Please input the interval in hours" },
                        ]}
                      >
                        <InputNumber min={0.1} step={0.1} />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          icon={<PlayCircleOutlined />}
                          loading={startLoading}
                        >
                          Start Automation
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                )}
              </Card>
            </>
          )
        )}
      </div>
    </Layout>
  );
};

export default HomePage;
