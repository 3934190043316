import Learn from '../Assets/learn.svg';

const Header = ({pageTitle}) => {
    return (
        <div></div>
        // <div className="pl-[20%] flex items-center justify-between px-6 py-7 bg-[#f5f5f5]">
        //     <div className="flex items-center">
        //         <img src={Learn} alt="learning-Icon" className="w-6 h-6 mr-2"/>
        //         <p className="text-xl">Teacher Panel</p>
        //     </div>
        // </div>

    );
};

export default Header;
